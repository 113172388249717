<div class="customer-order-details__view">
    <div class="customer-order-details__products">
        @for (orderProduct of customerOrder.orderProducts; track orderProduct) {
            <span class="customer-order-details__products__quantity dynamic__text--xs dynamic__text--bold"
                >{{ orderProduct.quantity }}x</span
            >

            <div class="customer-order-details__item">
                <div class="customer-order-details__products__options">
                    <span class="dynamic__text--xs dynamic__text--bold">{{ orderProduct.product.name }}</span>

                    @for (option of orderProduct.options; track option) {
                        <span class="dynamic__text--xxs">{{ option.name }}</span>
                    }
                </div>
                <span class="dynamic__text--xs dynamic__text--bold customer-order-details__item__no-shrink">{{
                    commonService.formatPriceString(orderProduct.product.price * orderProduct.quantity)
                }}</span>
            </div>
        }
    </div>

    <div class="customer-order-details__separator"></div>

    <div class="customer-order-details__prices">
        <div class="customer-order-details__item">
            <span class="dynamic__text--xs">Zwischensumme</span>
            <span class="dynamic__text--xs">{{ commonService.formatPriceString(customerOrder.total) }}</span>
        </div>
        <div class="customer-order-details__item">
            <span class="dynamic__text--xs dynamic__text--bold">Gesamt (inkl. MwSt.)</span>
            <span class="customer-order-details__item__no-shrink dynamic__text--xs dynamic__text--bold">{{
                commonService.formatPriceString(customerOrder.total)
            }}</span>
        </div>
    </div>

    <div class="customer-order-details__separator"></div>

    <div class="customer-order-details__payment">
        <span class="dynamic__text--xs dynamic__text--bold">Bezahlt mit</span>
        <span class="dynamic__text--xs">{{ commonService.formatPaymentType(customerOrder.paymentType) }}</span>
    </div>
</div>
