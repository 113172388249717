import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser } from '@angular/common';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';
import { PaymentType } from '../../lib/enums/PaymentType';

@Injectable({
    providedIn: 'root',
})
export class CommonService {
    constructor(
        private router: Router,
        @Optional() @Inject(PLATFORM_ID) private platformId: string,
    ) {}

    IsPlatformBrowser(): boolean {
        return isPlatformBrowser(this.platformId);
    }

    environment(): any {
        return environment;
    }

    navigate(route: string): void {
        void this.router.navigateByUrl(route);
    }

    isRouteActive(route: string): boolean {
        return this.router.isActive(route, true);
    }

    isMobile(maxPx = 1000): boolean {
        if (!this.IsPlatformBrowser()) return false;

        return window.innerWidth <= maxPx;
    }

    removeDoubleSpacesFromText(text: string): string {
        return text.replace(/\s{2,}/g, ' ');
    }

    scrollToTop(): void {
        if (!this.IsPlatformBrowser()) return;

        document.documentElement.scroll({
            top: 0,
            left: 0,
            behavior: 'smooth',
        });
    }

    formatPriceString(price: number | string): string {
        price = parseFloat(`${price}`);

        return price.toLocaleString('de-DE', {
            style: 'currency',
            currency: 'EUR',
        });
    }

    formatPaymentType(paymentType: PaymentType): string {
        switch (paymentType) {
            case PaymentType.ON_SPOT:
                return 'Bezahlung vor Ort';
            case PaymentType.PAYPAL:
                return 'PayPal';
            case PaymentType.STRIPE:
                return 'Stripe';
            default:
                return '';
        }
    }
}
