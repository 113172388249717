import { Component, Input } from '@angular/core';
import { Service } from '../../lib/interfaces/Service';

@Component({
    selector: 'app-service-info',
    standalone: true,
    imports: [],
    templateUrl: './service-info.component.html',
    styleUrl: './service-info.component.scss',
})
export class ServiceInfoComponent {
    @Input({ required: true }) service!: Service;
}
