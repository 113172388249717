import { Component, inject, Input } from '@angular/core';
import { Order } from '../../lib/interfaces/Order';
import { CommonService } from '../../services/common/common.service';
import { PaymentType } from '../../lib/enums/PaymentType';

@Component({
    standalone: true,
    selector: 'app-customer-order-details',
    templateUrl: './customer-order-details.component.html',
    styleUrl: './customer-order-details.component.scss',
    imports: [],
})
export class CustomerOrderDetailsComponent {
    @Input({ required: true }) customerOrder!: Order;

    protected paymentType?: PaymentType;

    protected commonService: CommonService = inject(CommonService);
}
