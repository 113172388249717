import { Component, inject } from '@angular/core';
import { CustomerService } from '../../services/customer/customer.service';
import { DynamoHttpService, DynamoListMenuItem } from '@skillgmbh/dynamo';

@Component({
    selector: 'app-customer-dashboard',
    templateUrl: './customer-dashboard.component.html',
    styleUrls: ['./customer-dashboard.component.scss'],
})
export class CustomerDashboardComponent {
    protected customerService: CustomerService = inject(CustomerService);
    protected httpService: DynamoHttpService = inject(DynamoHttpService);

    protected selectedDashboardMenuKey?: string = 'order-history';

    protected setSelectedDashboardMenuKey(listMenuOption: DynamoListMenuItem): void {
        this.selectedDashboardMenuKey = listMenuOption.key;
    }

    protected deleteCustomerAccount(): void {
        // TODO: Implement
    }
}
