import { Component, inject, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { OrderService } from '../../services/order/order.service';
import { DynamoListMenuItem } from '@skillgmbh/dynamo';
import { Product } from '../../lib/interfaces/Product';
import { CheckoutComponent } from '../checkout/checkout.component';
import { Event } from '../../lib/interfaces/Event';
import { CategoryService } from '../../services/category/category.service';
import { ActivatedRoute } from '@angular/router';
import { Service } from '../../lib/interfaces/Service';
import { ServiceService } from '../../services/service/service.service';
import { transition, trigger, useAnimation } from '@angular/animations';
import { OrderProductSelectOptionsComponent } from '../order-product-select-options/order-product-select-options.component';
import { OrderProduct } from '../../lib/interfaces/OrderProduct';
import { tada } from 'ngx-animate/lib/attention-seekers';
import { OverlayService } from '../../services/overlay/overlay.service';
import { CommonService } from '../../services/common/common.service';
import { filter } from 'rxjs';
import { environment } from '../../../environments/environment';

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'orderBy',
    standalone: true,
})
export class OrderByPipe implements PipeTransform {
    transform(value: any[], field: string): any[] {
        if (!value || !field) {
            return value;
        }
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        return value.sort((a, b) => (a[field] > b[field] ? 1 : a[field] < b[field] ? -1 : 0));
    }
}

@Component({
    selector: 'app-order',
    templateUrl: './order.component.html',
    styleUrls: ['./order.component.scss'],
    animations: [trigger('shake', [transition('off => on', useAnimation(tada))])],
})
export class OrderComponent implements OnInit, OnDestroy {
    protected readonly environment: typeof environment = environment;

    @ViewChild('orderProductSelectOptions') orderProductSelectOptions: OrderProductSelectOptionsComponent =
        {} as OrderProductSelectOptionsComponent;
    @ViewChild('checkout') checkout: CheckoutComponent = {} as CheckoutComponent;

    @Input() event: Event | undefined;

    listMenuOptions: DynamoListMenuItem[] = [];

    service: Service = {} as Service;

    showAnimation: string = 'off';
    mobileBreakPx: number = 1000;

    protected previewEnabled: boolean = false;

    protected commonService: CommonService = inject(CommonService);
    protected activatedRoute: ActivatedRoute = inject(ActivatedRoute);
    protected orderService: OrderService = inject(OrderService);
    protected categoryService: CategoryService = inject(CategoryService);
    protected serviceService: ServiceService = inject(ServiceService);
    private overlayService: OverlayService = inject(OverlayService);
    private viewContainerRef: ViewContainerRef = inject(ViewContainerRef);

    constructor() {
        const serviceUID: string | null = this.activatedRoute.snapshot.paramMap.get('serviceUID');

        if (!serviceUID) return;

        this.serviceService.fetchByUID(serviceUID).subscribe((service: Service): void => {
            if (!service) {
                this.commonService.navigate('bestellen');
                return;
            }

            this.service = service;
            this.orderService.order.service = service;
            this.categoryService.fetchCategories(service.id);
            this.orderService.fetchAvailableProducts(serviceUID);

            if (this.orderService.order.orderProducts?.length) return;

            this.orderService.order.orderProducts = [];
            this.listMenuOptions.push();
        });
    }

    ngOnInit(): void {
        this.orderService.productMarkedForOptionSelection$.pipe(filter(Boolean)).subscribe((product: Product): void => {
            this.overlayService.open(this.viewContainerRef, OrderProductSelectOptionsComponent, {
                title: product.name,
                iconType: 'select',
                description: 'Bitte wähle eine Option aus',
            });

            this.triggerCheckoutButtonAnimation();
        });
    }

    ngOnDestroy(): void {
        this.overlayService.close();
    }

    addProductToCheckout(product: Product): void {
        if (this.previewEnabled) return;

        if (product.options.length) {
            this.orderService.productMarkedForOptionSelectionSubject$.next(product);

            return;
        }

        this.orderService.registerOrderProduct(product, []);
        this.triggerCheckoutButtonAnimation();
    }

    triggerCheckoutButtonAnimation(): void {
        this.showAnimation = 'on';
        setTimeout((): void => {
            this.showAnimation = 'off';
        }, 1000);
    }

    calculateProductCountCheckout(): number {
        let count = 0;
        this.orderService.order.orderProducts.forEach((orderProduct: OrderProduct): void => {
            count += orderProduct.quantity;
        });
        return count;
    }
}
