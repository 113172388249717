import { Component, Input, OnInit } from '@angular/core';
import { OrderProduct } from '../../lib/interfaces/OrderProduct';
import { OrderService } from '../../services/order/order.service';
import { Router } from '@angular/router';
import { ServiceService } from '../../services/service/service.service';
import { Service } from '../../lib/interfaces/Service';
import { take } from 'rxjs';
import { CommonService } from '../../services/common/common.service';

@Component({
    selector: 'app-checkout',
    templateUrl: './checkout.component.html',
    styleUrls: ['./checkout.component.scss'],
})
export class CheckoutComponent implements OnInit {
    @Input() service?: Service;

    constructor(
        public router: Router,
        public commonService: CommonService,
        public orderService: OrderService,
        public serviceService: ServiceService,
    ) {}

    ngOnInit(): void {
        if (this.service) return;

        this.commonService.scrollToTop();

        this.serviceService
            .fetchByUID(this.serviceService.fetchServiceUIDByLocation()!)
            .pipe(take(1))
            .subscribe((service: Service): void => {
                this.service = service;
            });
    }

    continueCheckout(): void {
        if (!this.orderService.order.orderProducts.length) return;

        void this.router.navigateByUrl(`/info/${this.service?.uid}`);
    }

    fetchOrderTotal(): string {
        let total = 0;
        for (const orderProduct of this.orderService.order.orderProducts) {
            total += Math.max(0, this.calculateOrderProductPrice(orderProduct));
        }
        return total.toFixed(2);
    }

    removeProductFromCheckout(orderProduct: OrderProduct): void {
        this.orderService.unregisterOrderProduct(orderProduct);
    }

    calculateOrderProductPrice(orderProduct: OrderProduct): number {
        orderProduct.fullPrice = orderProduct.product.price;

        if (!orderProduct.options) return orderProduct.quantity * orderProduct.fullPrice;

        for (const option of orderProduct.options) {
            orderProduct.fullPrice += option.price;
        }

        return Math.max(0, orderProduct.quantity * orderProduct.fullPrice);
    }

    setOrderProductQuantity(orderProduct: OrderProduct, quantity: string | number): void {
        orderProduct.quantity = typeof quantity === 'string' ? Number(quantity) : quantity;
    }
}
