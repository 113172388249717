@if (
    ((orderService.serviceEnabled$ | async) && (categoryService.categoryCollection$ | async)?.length) ||
    event?.orderEnabled || previewEnabled
) {
    <div class="order__view dynamic__stretch--width">
        <div class="order__order-area"
            [class.order__order-area--full-width]="previewEnabled">
            <div class="order__header dynamic__stretch--width">
                <div class="order__header__image">
                    <dynamo-image
                        height="100%"
                        width="100%"
                        backgroundSize="cover"
                        source="{{ environment.service_proxy }}attributes/get/preview/banner/{{ service.id }}"
                        dynamoImageSource>
                    </dynamo-image>
                </div>
            </div>

            <div class="order__list dynamic__stretch--width">
                @for (category of categoryService.categoryCollection$ | async; track category) {
                    <div class="order__list__category">
                        <span class="order__list__category-title dynamic__title--sm dynamic__text--bold">
                            {{ category.name }}
                        </span>
                        <div class="order__list__category__products dynamic__stretch--width">
                            @for (product of category.products | orderBy: 'order'; track product) {
                                <div
                                    class="order__list__category__products__product"
                                    (click)="addProductToCheckout(product)">
                                    <span fxLayout="column" fxLayoutGap="1rem">
                                        <span fxLayout="column" fxLayoutGap=".3rem">
                                            <span class="dynamic__text--sm dynamic__text--bold">{{
                                                commonService.removeDoubleSpacesFromText(product.name)
                                            }}</span>
                                            @if (!!product.description) {
                                                <span
                                                    class="dynamic__text--xs dynamic__text--accent order__list__category__products__product__description"
                                                    >{{
                                                        commonService.removeDoubleSpacesFromText(product.description)
                                                    }}</span
                                                >
                                            }
                                        </span>

                                        <span class="dynamic__text--xs dynamic__text--bold">
                                            {{ (product.price ?? 0).toFixed(2)?.replace('.', ',') }} €
                                        </span>
                                    </span>
                                    <span class="order__list__category__products__product__add-to-cart-button">
                                        <dynamo-icon
                                            class="order__list__category__products__product__add-to-cart-button__icon"
                                            dynamoIconType="shopping-bag"
                                            manualSize="1.5rem">
                                        </dynamo-icon>
                                    </span>
                                </div>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>

        @if (!commonService.isMobile(1000) && !previewEnabled) {
            <app-checkout #checkout class="order__checkout" [service]="service"></app-checkout>
        }

        @if (commonService.isMobile(mobileBreakPx) && (orderService.serviceEnabled$ | async)) {
            <span class="order__checkout-button-mobile dynamic__tile">
                <span class="order__button" routerLink="/checkout/{{ service.uid }}" [@shake]="showAnimation">
                    <span class="order__button__icon dynamic__title--sm--xs">
                        <mat-icon>shopping_cart</mat-icon>
                        <span>Zum Warenkorb ({{ calculateProductCountCheckout() }} ausgewählt)</span>
                    </span>
                </span>
            </span>
        }
    </div>
}

@if (!(orderService.serviceEnabled$ | async) && event !== null && event?.orderEnabled !== true && !previewEnabled) {
    <div class="order__info dynamic__padding--lg">
        <span class="dynamic__title--sm dynamic__text--center">Aktuell nehmen wir leider keine Bestellungen an.</span>
        <span class="dynamic__button" (click)="previewEnabled = true">Menü ansehen</span>
        @if(service.schedules.length) {
            <div class="order__info__schedules">
                <span class="dynamic__text">Unsere Bestellzeiten</span>
                <app-order-times [schedules]="service.schedules"></app-order-times>
            </div>
        }
    </div>
}
